import React from "react";
import { Link, graphql } from "gatsby"
import Row from 'react-bootstrap/Row'
import Jumbotron from 'react-bootstrap/Jumbotron'
import SEO from "../components/seo"

import Col from 'react-bootstrap/Col'
import payImg from "../../static/pay-sub-yt-960_720.jpg"
import tvImg from "../../static/bear-bed-bedroom-dj.jpg"


const About = ({data}) => {
    const siteTitle = data.site.siteMetadata.title

    return (
       <div className="page-wrapper">
            <SEO 
                title="About" 
                description="At Subflipper you can buy and sell subscriptions for any service, tool or software with any expiration date. 
                This gives you the freedom to use subscription services only for the time they're actually needed, and to get money back from unused subscriptions."
                pagePath="/about"
            />

            <h1>About</h1>
            <div className="divider-hr">
                <span className="divider-span"></span>
            </div>

            <p className="about-desc">
                At {siteTitle} you can buy and sell subscriptions for any service, tool or software with any expiration date. This gives you the freedom 
                to use subscription services only for the time they're actually needed, and to get money back from unused subscriptions.
            </p>

        <div>
            <Row lg="2" sm="1" xs="1" noGutters>
                <Col style={{backgroundImage: "url(" + tvImg + ")", minHeight: "30rem", backgroundSize: "cover"}}>
                </Col>

                <Jumbotron class="col-lg-6 order-lg-1 my-auto showcase-text">
                    <h2>Limited and cheap subscriptions</h2>
                    <p class="lead mb-0">
                        When buying subscriptions with limited time left you can get a cheap price and still enjoy the all the content 
                        that the service offers. Maybe there's a movie you want to see on Netflix and you don't need to buy that full month. Or there's a tool you need
                        to use for a specific project and don't want to sign up for a long subscription.
                    </p>
                </Jumbotron>
            </Row>

            <Row lg="2" sm="1" xs="1" noGutters>
                <Jumbotron class="col-lg-6 order-lg-1 my-auto showcase-text">
                    <h2>Selling subs</h2>
                    <p class="lead mb-0">
                        Get money back from subscriptions you're not using anyway. You could for example sell your Netflix, HBO or Youtube that you're not using anymore. 
                        Or perhaps you've subscribed to a game you're no longer playing or some software you don't have any use for.
                    </p>
                </Jumbotron>
                <Col style={{backgroundImage: "url(" + payImg + ")", minHeight: "30rem", backgroundSize: "cover"}}>
                </Col>
            </Row>  
        </div>

            <Link className="btn btn-primary btn-xl rounded-pill mt-5" to="/">Check out the listings</Link>

        </div>
    )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`